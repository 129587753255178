import { graphql, useStaticQuery } from 'gatsby'
import { getFluidImage } from 'helpers/getFluidImage'

export const fetchErrorImg = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "404/image404.png" }) {
        base
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return getFluidImage(file)
}
