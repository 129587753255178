import React, { FunctionComponent } from 'react'
// @ts-ignore
import { LocalizedLink } from 'gatsby-theme-i18n'
import { Trans } from "@lingui/macro"
import {
  ErrorContainer,
  ErrorContent,
  ErrorImage,
  ErrorSection,
  ErrorTitle,
} from 'styles/pages/error.style'
import { ButtonRed, PrimaryTitle } from 'styles/Global'

import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Header from 'components/organisms/Header/Header'
import { fetchErrorImg } from 'hooks/fetch-error-img'
import Image from 'components/Image'

interface Props {}

const NotFoundPage: FunctionComponent<Props> = () => {
  const image = fetchErrorImg()

  return (
    <Layout>
      <SEO title="404" />
      <Header darkMode />
      <ErrorSection>
        <ErrorContainer>
          <ErrorContent>
            <ErrorTitle>404</ErrorTitle>
            <PrimaryTitle as="h2">
              <Trans id="404.title">
                Vous êtes perdu dans le cosmos ?
              </Trans>
            </PrimaryTitle>
            <ButtonRed as={LocalizedLink} to="/">
              <Trans id="404.button">
                Retournez à la page d’accueil
              </Trans>
            </ButtonRed>
          </ErrorContent>
          <ErrorImage>
            <Image fluid={image.fluid} alt={image.alt} />
          </ErrorImage>
        </ErrorContainer>
      </ErrorSection>
    </Layout>
  )
}

export default NotFoundPage
