import styled from 'styled-components'
import { Container, PrimaryTitle } from 'styles/Global'

export const ErrorSection = styled.section`
  background: ${({ theme }) => theme.Colors.mainBlack};
  color: ${({ theme }) => theme.Colors.white};
  height: calc(100vh - 95px);
  margin-top: 95px;
  overflow: hidden;
`

export const ErrorContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const ErrorContent = styled.div`
  text-align: center;

  ${PrimaryTitle} {
    margin-bottom: 2.5rem;
  }
`

export const ErrorTitle = styled.h1`
  font-size: 6rem;
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  margin-bottom: 1rem;
  padding-top: 4rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-top: 2.5rem;
    font-size: 4.5rem;
  }
`

export const ErrorImage = styled.div`
  width: 610px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`
